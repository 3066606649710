<template>
  <div class="pa-2">
    <v-card>
      <v-card-title
        ><div></div>
        <v-spacer></v-spacer>
        <div>
          <!-- <v-btn
            @click="
              $router.push({
                name: $route.name + '-create',
                params: { api: $route.params.api, view: $route.params.view },
              })
            "
            color="primary"
            class="ma-1"
          >
            Создать
          </v-btn> -->
        </div></v-card-title
      >
      <v-card-text :style="{ height: contentHeight + 'px' }">
        <a-table-f-api
          ref="table"
          :api="getUrl"
          :model="model"
          :useQuery="true"
          :defaults="getConfigView(viewName).index.defaults"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import genModel from "@/share/components/libs/genModel";
import table from "./../table";

export default {
  components: {
  },
  mixins: [table, genModel],
  data() {
    return {
    };
  },
  computed: {
    getUrl() {
      return this.$root.api[this.$route.params.api];
    },
    model() {
      this.$root.title = this.getConfigView(this.viewName).name;
      return this.modelIndex;
    },
  },
  methods: {
  },
};
</script>